import styled from "styled-components";

export const RightBlockContainer = styled("section")`
  position: relative;
  padding: 8rem 0 6rem;

  @media only screen and (max-width: 1024px) {
    padding: 4rem 0 4rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 4rem 0 3rem;
  }
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    max-width: 80%;
  }

  button:last-child {
    margin-left: 20px;
  }
`;

export const LeftContentSection = styled("section")`
  position: relative;
  padding: 10rem 0 8rem;

  @media only screen and (max-width: 1024px) {
    padding: 4rem 0 4rem;
  }
`;

export const Content = styled("p")`
  margin: 1.5rem 0 2rem 0;
`;

export const ContentWrapper = styled("div")`
  position: relative;
  max-width: 540px;
  overflow: hidden;

  /* Irregular curved shapes */
  &::before {
    content: '';
    position: absolute;
    top: -50px; /* Move it further outside to keep it visually balanced */
    left: -20px;
    width: 400px; /* Increase size */
    height: 300px; /* Increase size */
    background: #d2f2fe;
    clip-path: path("M0,100 C50,0 150,0 200,100 C250,200 150,200 100,150 C50,100 0,200 0,100 Z");
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -120px; /* Move it further outside */
    right: -140px;
    width: 450px; /* Increase size */
    height: 300px; /* Increase size */
    background: #d2f2fe;
    clip-path: path("M0,50 C75,-50 225,-50 300,50 C375,150 125,150 150,100 C75,50 0,150 0,50 Z");
    z-index: -1;
  }

  @media only screen and (max-width: 575px) {
    padding-top: 4rem;
  }
`;

export const ServiceWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

export const ImageWrapper = styled("div")`
  .svg-home{
    width: 100%;

    @media only screen and (min-width: 575px) {
    width: 100%;
  }
  }
`;

export const MinTitle = styled("h6")`
  font-size: 15px;
  line-height: 1rem;
  padding: 0.5rem 0;
  text-transform: uppercase;
  color: #000;
  font-family: "Motiva Sans Light", sans-serif;
`;

export const MinPara = styled("p")`
  font-size: 13px;
`;
