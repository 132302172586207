import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Styles } from "../styles/styles";
import Home from "../pages/Home";
import ResetPassword from "../pages/ResetPassword";
import ForgetPassword from "../pages/ForgetPassword";
import AdminLogin from "../pages/AdminLogin";
import AdminDashboard from "../pages/AdminDashboard";
import TermsBlock from '../pages/TermsAndPrivacy/terms'
import PrivacyBlock from '../pages/TermsAndPrivacy/privacy'

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/email" element={<ForgetPassword />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/terms-and-conditions" element={<TermsBlock />} />
        <Route path="/privacy-policy" element={<PrivacyBlock />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
